import React from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql, Link /* , navigate */ } from "gatsby"

import {
  Navbar,
  Nav,
  NavDropdown /* , Container, Col, Row */,
} from "react-bootstrap"

import MyStyles from "./navBar.module.css"

/* const MyMenu = {
  to: [
    "/",
    "storia",
    [""],
    "cda",
    "team",
    "business-opportunities"[("contatti", "lavora_con_noi")],
  ],
} */

const CustomNavbar = ({ pageInfo, location }) => (
  /* ...GatsbyImageSharpFixed */
  <StaticQuery
    query={graphql`
      {
        file(relativePath: { eq: "loghi/archimede_nav.png" }) {
          name
          childImageSharp {
            fixed(pngQuality: 70, height: 60) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
    `}
    render={data => (
      <>

        <Navbar fixed="top" bg="light" expand="lg" className="px-4 py-1">
          <Link to="/" className="navbar-brand link-no-style p-0" alt="Logo" >
            {/* <Navbar.Brand>Archimede</Navbar.Brand> */}
            <div className="">
              <Img fixed={data.file.childImageSharp.fixed} alt="" />
            </div>
          </Link>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              className="ml-auto mr-5"
              activeKey={pageInfo && pageInfo.pageName}
            >
              <MyLink2 to="/" className="link-no-style">
                {/* <Nav.Link as="span" className="font-weight-normal text-dark">Home</Nav.Link> */}
                Home
              </MyLink2>

              <MyDropDown bsPrefix="" activeroutes={["/storia","/gruppo", "/gruppo"]} location={location} title="Gruppo" id="basic-nav-dropdown1" >
                
                {/* <Link to="/storia" className="dropdown-item" state={{ is_scroll: true, path: "#storia", time: 600, }} > */}
                <Link to="/storia" className="dropdown-item" state={{ is_scroll: true, path: "#storia", time: 600, }} >
                  <Nav.Link as="span" className="text-dark text-center"> Storia </Nav.Link>
                </Link>
                
                <NavDropdown.Divider />

                <Link to="/gruppo" className="dropdown-item" state={{ is_scroll: true, path: "#societa_controllate", time: 600, }} >
                  <Nav.Link as="span" className="text-dark text-center"> Società controllate </Nav.Link>
                </Link>

                <Link to="/gruppo" className="dropdown-item" state={{ is_scroll: true, path: "#societa_partecipate", time: 600, }} >
                  <Nav.Link as="span" className="text-dark text-center"> Società partecipate </Nav.Link>
                </Link>
                {/* <NavDropdown.Divider /> */}
              </MyDropDown>
              



              

              <MyLink2 to="/team" className="link-no-style"> Team </MyLink2>
              
              <MyLink2 to="/ricercaesviluppo/horizon" className="link-no-style"> Attività R&D </MyLink2> 
              {/* <MyLink2 to="/ricercaesviluppo" className="link-no-style"> Attività R&D </MyLink2>  */}
              {/* &amp; */}
              
              <MyLink2 to="/business-opportunities" className="link-no-style"> Business opportunities </MyLink2>

              {/* <MyLink2 to="/storia" className="link-no-style"> Storia </MyLink2> */}
              {/* <MyLink2 to="/cda" className="link-no-style"> CDA </MyLink2> */}

              
              
              
            
              <MyDropDown bsPrefix="" className="nav-link" activeroutes={["/contatti", "/lavora_con_noi"]} location={location} title="Info" id="basic-nav-dropdown2" 
              >
                <Link to="/contatti" className="dropdown-item" state={{ is_scroll: true, path: "#form", time: 600, }} >
                  <Nav.Link as="span" className="text-dark text-center"> Contatti </Nav.Link>
                </Link>

                <Link to="/lavora_con_noi" className="dropdown-item" state={{ is_scroll: true, path: "#form", time: 600, }} >
                  <Nav.Link as="span" className="text-dark text-center"> Lavora con noi </Nav.Link>
                </Link>

                {/* <Link to="/servizi" className="dropdown-item" >
                  <Nav.Link as="span" className="text-dark text-center"> Servizi </Nav.Link>
                </Link> */}

                  {/* <NavDropdown.Divider /> */}
              </MyDropDown>

              <MyLink2 to="/servizi" className="link-no-style"> Servizi </MyLink2>
           

              <MyLink2 to="/pubblicazioni" className="link-no-style"> Pubblicazioni </MyLink2>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </>
    )}
    
  ></StaticQuery>
)

/* let activeStyle = {
  borderBottom: `1px solid #ccc`,
} */

function isActiveRoutes(routes, location) {
  let location_pathname = ""

  if (Array.isArray(routes)) {
    if (typeof location !== "undefined") {
      location_pathname = location.pathname
      /* console.log(location_pathname) */
    }

    const childRouteIsActive = routes.find(
      /* element => element === location_pathname */
      element => {
        return location_pathname.includes(element)
      }
    )
    if (childRouteIsActive !== undefined) {
      return true
    }
  }
  return false
}

const MyDropDown = props => {
  return (
    <NavDropdown
      {...props}
      className={
        isActiveRoutes(props.activeroutes, props.location)
          ? `${props.bsPrefix}text-dark mx-1 shadow ${MyStyles.select_page} bg-white rounded`
          : `${props.bsPrefix}text-dark mx-1 ${MyStyles.unselect_page} bg-white rounded`
      }
      
      bsPrefix="text-dark nav-link text-center"
    />
  )
}
/* 
const MyLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      // color: isCurrent ? "red" : "blue",
      return {
        className: `${props.className} mx-1 ${
          isCurrent
            ? `border ${MyStyles.select_page} shadow bg-white rounded`
            : `${MyStyles.unselect_page} my-1 bg-white rounded`
        }`,
      }
    }}
  > 
    {props.children}
  </Link>
) */

const MyLink2 = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      // color: isCurrent ? "red" : "blue",
      return {
        className: `${props.className} text-center mx-1 ${
          isCurrent
            ? `${MyStyles.select_page} shadow bg-white rounded`
            : `${MyStyles.unselect_page} bg-white rounded`
        }`,
      }
    }}
  >
    {/* old return return {
        
        className: `${props.className} mx-1`,
        style: {
          borderBottom: isCurrent ? `1px solid blue` : `0px solid #a09b9b`,
          backgroundColor: isCurrent ? `#eeeeee` : ``,
        },
      } */}

    {/* <div className="my-2 p-1">{props.children}</div> */}
    <Nav.Link as="span" className="font-weight-normal text-dark">
      {props.children}
    </Nav.Link>
  </Link>
)

/* const MyNavDrop = props => (
  <NavDropdown
    {...props}
    bsPrefix="my-2 p-1 nav-link"
    
  >
    {props.children}
  </NavDropdown>
) */

export default CustomNavbar
